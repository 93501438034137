import React from "react";
import styles from "./home.module.css";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import ContactStrip from "../../components/ContactStrip";
import { FaCircleArrowRight } from "react-icons/fa6";
import BrochureDwnld from "../../components/BrochureDwnld";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Interview as a Service: Streamline Your Recruitment Process
        </title>
        <meta
          name="description"
          content="Improve your hiring efficiency with Improviu's interview as a service. Save time, reduce costs, and hire top talent faster."
        />
        <meta
          name="keywords"
          content="interview questions, interviewer, interview, Interview as a service, Talent acquisition, Hiring, Recruitment, Recruitment process outsourcing, Virtual Interview, Video technical assessment, recruitment hiring process"
        />
      </Helmet>
      <div className={styles.homeVideoContainer}>
        <video
          autoPlay
          muted
          preload="auto"
          playsInline
          loop
          className={styles.homeVideo}
          src="/assets/video/home-video.mp4"
        ></video>

        <div className={styles.overlay}>
          <div className={styles.overlayContent}>
            <div className={`text-center ${styles.headContainer}`}>
              <h1 className={`${styles.homeHeading} lato-black mb-0`}>
                Interview as a Service
              </h1>
              <h6>Interview Outsourcing Redefinied</h6>
            </div>

            <div style={{ overflowX: "auto", width: "100%" }}>
              <Container fluid style={{ width: "100%" }}>
                <Row>
                  <Col
                    md={4}
                    className="mb-4 p-1 p-md-5 pe-md-4 ps-md-4 p-lg-5"
                  >
                    <div className={styles.cardDiv}>
                      <h5 className="mb-0">EMPLOYER</h5>
                    </div>
                    <Card
                      className="h-100 shadow-sm"
                      style={{
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        position: "relative",
                      }}
                    >
                      <Card.Body className="d-flex flex-column justify-content-between text-white">
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Faster Time-to-Hire
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Objective Feedback and Reporting
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Video Recording for Transparency
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Cost efficiency, Flexibility & Scalability
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Access to Expert Interviewers
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Value Hiring & Better Applicant Experience
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col
                    md={4}
                    className="mb-4 p-1 p-md-5 pe-md-4 ps-md-4 p-lg-5"
                  >
                    <div className={styles.cardDiv}>
                      <h5 className="mb-0">INTERVIEWER</h5>
                    </div>
                    <Card
                      className="h-100 shadow-sm"
                      style={{
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                      }}
                    >
                      <Card.Body className="d-flex flex-column justify-content-between text-white">
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          High Potential to Earn
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Free Time Utilized to Earn & Learn
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Interviewing Improves Skills Better
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Better Preparation and Learning
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Secure Interviewing Process
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Access to ResourceTree Community
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col
                    md={4}
                    className={`mb-4 p-md-5 pe-md-4 ps-md-4 p-lg-5 ${styles.lastCardCol}`}
                  >
                    <div className={styles.cardDiv}>
                      <h5 className="mb-0"> MOCK INTERVIEW</h5>
                    </div>
                    <Card
                      className="h-100 shadow-sm"
                      style={{
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                      }}
                    >
                      <Card.Body className="d-flex flex-column justify-content-between text-white">
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Receive Expert Feedback & Advice
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Experience Real-World Scenarios
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Build Confidence and Reduce Anxiety
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Identify and Correct Weaknesses
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Enhance your Interviewing Skills
                        </Card.Text>
                        <Card.Text className={styles.cardText}>
                          <FaCircleArrowRight
                            style={{ marginRight: "8px", color: "whitesmoke" }}
                          />
                          Better Ability to clear Realtime Interview
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <Container>
          <p className="textJustify">
            Outsourcing interviews is becoming increasingly popular as
            businesses seek to improve operational efficiency. This strategic
            move allows hiring managers to focus on other critical tasks, rather
            than sifting through applications and conducting numerous
            interviews. "Improviu – Interview as a Service" offers a seamless
            solution to this challenge. By leveraging the expertise of over one
            hundred seasoned interviewers who have collectively conducted more
            than 5,000 interviews, companies can significantly enhance their
            recruitment processes. The benefits of using outsourced interviewing
            services include increased time efficiency, higher quality hires,
            faster recruitment cycles, and substantial cost savings. "Improviu"
            provides tailored interview services to meet your specific needs,
            greatly alleviating the burdens of the hiring process.
          </p>
          <p className="textJustify">
            For many clients, this is an ideal solution as it alleviates hiring
            pain points and shortens recruitment timelines. Hiring managers
            become more productive by focusing on key deliverables instead of
            conducting multiple interview rounds, a task we handle for them.
            ResourceTree’s Interview Panel comprises skilled professionals with
            5 to 30 years of industry experience. Currently, Improviu offers
            expertise in over 50 key IT skills. If a specific skill set is not
            available, we can arrange it within one week to meet your needs
          </p>
        </Container>
      </div>
      <div className="mt-4">
        <h2 className="text-center mb-4">BENEFITS OF IMPROVIU</h2>
        <Container>
          <Row>
            <Col
              md={6}
              className="d-flex flex-column justify-content-center align-items-center px-3 py-2 py-md-0 px-md-5"
            >
              <h4 className="subHeading">Organisations</h4>
              <ul>
                <li>40% of Hiring Managers Time Saved</li>
                <li>30% of Hiring Cost Saved</li>
                <li>25% Increase in Quality of Hires</li>
                <li>Identify Fake Candidates</li>
                <li>35% Faster Hiring Process</li>
                <li>5000 Interviews Completed</li>
                <li>100+ Interview Panels</li>
                <li>130 Tech Skills</li>
                <li>90 Days Video Interview Storage</li>
                <li>Accurate Ratings and Extensive Feedbacks</li>
              </ul>
            </Col>
            <Col md={6} className="px-md-0">
              <Image
                style={{ width: "100%" }}
                alt="organisations"
                src="/assets/organisations.png"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="px-md-0 order-1 order-md-0">
              <Image
                style={{ width: "100%" }}
                alt="interviewer"
                src="/assets/interviewer.png"
              />
            </Col>
            <Col
              md={6}
              className="d-flex flex-column justify-content-center align-items-center px-3 py-2 py-md-0 px-md-5 order-0 order-md-1"
            >
              <h4 className="subHeading">Interviewer</h4>
              <ul>
                <li>High Potential to Earn</li>
                <li>Free Time Utilized to Interview and Earn</li>
                <li>Interviewing Improves your Skills Better</li>
                <li>Better Preparation and Learning</li>
                <li>Improving Gig Economy</li>
                <li>The Convenience and Secure Interviewing Process</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              className="d-flex flex-column justify-content-center align-items-center px-3 py-2 py-md-0 px-md-5"
            >
              <h4 className="subHeading">Mock Interviewer</h4>
              <ul>
                <li>Realtime Feedback on Skills and Experience</li>
                <li>Expert Advice on Improvement</li>
                <li>Better Preparation for Company Interviews</li>
                <li>Higher Success Ratio to Clear Client Rounds</li>
                <li>Improved Confidence and Preparation</li>
                <li>Self Assessment to Upgrade Suggestions</li>
              </ul>
            </Col>
            <Col md={6} className="px-md-0">
              <Image
                style={{ width: "100%" }}
                alt="Mock Interviewer"
                src="/assets/mockInterviewer.png"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mt-4">
        <BrochureDwnld />
      </div>
      <div className="mb-4">
        <ContactStrip />
      </div>
    </>
  );
};

export default Home;
