import React, { useState } from "react";
import styles from "./navbar.module.css";
import {
  Navbar,
  Container,
  Offcanvas,
  Image,
  Row,
  Col,
  Button,
  Dropdown,
  Nav,
} from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { IoMdArrowDropright } from "react-icons/io";
import { IoWarning } from "react-icons/io5";
import DisclamerModal from "../DisclamerModal";

const NavBar = () => {
  const naviagte = useNavigate();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setIsServicesOpen(false);
  };
  const handleShow = () => setShow(true);

  const handleOpenLogin = () => {
    window.open("https://app.improviu.co.in/signup");
  };

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleServicesClick = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const handleOpenDisclamerModal = () => {
    setModalShow(true);
  };

  const servicePath =
    location.pathname === "/organisations" ||
    location.pathname === "/interviewers" ||
    location.pathname === "/candidates";

  return (
    <>
      <DisclamerModal modalShow={modalShow} setModalShow={setModalShow} />
      <Navbar
        fixed="top"
        expand="lg"
        style={{
          background: "#efefef",
        }}
      >
        <Container fluid style={{ height: "45px" }} className="px-0">
          <Row
            className="d-flex align-items-center w-100 ml-0"
            style={{ marginLeft: "0px" }}
          >
            <Col md={2} xs={6}>
              <Image
                onClick={() => naviagte("/")}
                src="/assets/mainlogo.png"
                alt="Improviu"
                className={styles.logo}
              />
            </Col>
            <Col
              md={10}
              className="d-none d-md-flex align-items-center justify-content-end gap-3 px-2"
            >
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `${isActive ? "activeLink" : "normalLink"}`
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/about-us"
                className={({ isActive }) =>
                  `${isActive ? "activeLink" : "normalLink"}`
                }
              >
                About Us
              </NavLink>
              <Dropdown
                style={{ marginTop: "-2px" }}
                show={showDropdown}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={toggleDropdown}
              >
                <NavLink
                  className={`${
                    servicePath ? "activeLink" : "normalLink"
                  }`}
                >
                  Services{" "}
                  {showDropdown ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  )}
                </NavLink>
                <Dropdown.Menu className={`${styles.dropdownNav}`}>
                  <Nav.Item style={{ marginBottom: "10px" }}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "activeLink" : "normalLink"
                      }
                      to="/organisations"
                    >
                      For Organisations
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item style={{ marginBottom: "10px" }}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "activeLink" : "normalLink"
                      }
                      to="/interviewers"
                    >
                      For Interviewers
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item style={{ marginBottom: "10px" }}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "activeLink" : "normalLink"
                      }
                      to="/candidates"
                    >
                      For Candidates
                    </NavLink>
                  </Nav.Item>
                </Dropdown.Menu>
              </Dropdown>

              <NavLink
                to="/pricing"
                className={({ isActive }) =>
                  `${isActive ? "activeLink" : "normalLink"}`
                }
              >
                Pricing
              </NavLink>
              <NavLink
                to="/blogs"
                className={({ isActive }) =>
                  `${isActive ? "activeLink" : "normalLink"}`
                }
              >
                Blog
              </NavLink>
              <NavLink
                to="/contact-us"
                className={({ isActive }) =>
                  `${isActive ? "activeLink" : "normalLink"}`
                }
              >
                Contact Us
              </NavLink>
              <IoWarning
                onClick={handleOpenDisclamerModal}
                style={{
                  color: "#ffbc03",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
              />
              <Button onClick={handleOpenLogin} className={styles.loginBtn}>
                Sign Up / Log In
              </Button>
              <Button 
              onClick={() => {
                naviagte("/contact-us")
                handleClose();
              }}
              className={styles.loginBtn}>
                Demo
              </Button>
            </Col>
            <Col
              xs={6}
              className="d-flex d-md-none justify-content-end align-items-center"
            >
              <GiHamburgerMenu
                size={20}
                color="black"
                onClick={handleShow}
                style={{ cursor: "pointer" }}
              />
            </Col>
          </Row>
        </Container>
      </Navbar>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Image
            src="assets/mainlogo.png"
            alt="logo"
            style={{ height: 40, width: "50%", objectFit: "contain" }}
            onClick={() => {
              handleClose();
              naviagte("/");
            }}
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="d-flex flex-column gap-4">
            <NavLink
              onClick={handleClose}
              to="/"
              className={({ isActive }) =>
                `${isActive ? "activeLink" : "normalLink"}`
              }
            >
              Home
            </NavLink>
            <NavLink
              onClick={handleClose}
              to="/about-us"
              className={({ isActive }) =>
                `${isActive ? "activeLink" : "normalLink"}`
              }
            >
              About Us
            </NavLink>

            <div onClick={handleServicesClick}>
              <span
                className={`${
                  servicePath ? "activeLink" : "normalLink"
                }`}
              >
                Services
                {isServicesOpen ? (
                  <MdKeyboardArrowUp />
                ) : (
                  <MdKeyboardArrowDown />
                )}
              </span>
            </div>
            {isServicesOpen && (
              <div
                className="d-flex flex-column gap-3"
                style={{ marginTop: "-10px" }}
              >
                <NavLink
                  onClick={handleClose}
                  to="/organisations"
                  className={({ isActive }) =>
                    `${
                      isActive ? "activeLink" : "normalLink"
                    } d-flex align-items-center`
                  }
                >
                  <IoMdArrowDropright />
                  For Organisations
                </NavLink>
                <NavLink
                  onClick={handleClose}
                  to="/interviewers"
                  className={({ isActive }) =>
                    `${
                      isActive ? "activeLink" : "normalLink"
                    } d-flex align-items-center`
                  }
                >
                  <IoMdArrowDropright />
                  For Interviewers
                </NavLink>
                <NavLink
                  onClick={handleClose}
                  to="/candidates"
                  className={({ isActive }) =>
                    `${
                      isActive ? "activeLink" : "normalLink"
                    } d-flex align-items-center`
                  }
                >
                  <IoMdArrowDropright />
                  For Candidates
                </NavLink>
              </div>
            )}

            <NavLink
              onClick={handleClose}
              to="/pricing"
              className={({ isActive }) =>
                `${isActive ? "activeLink" : "normalLink"}`
              }
            >
              Pricing
            </NavLink>
            <NavLink
              onClick={handleClose}
              to="/blogs"
              className={({ isActive }) =>
                `${isActive ? "activeLink" : "normalLink"}`
              }
            >
              Blog
            </NavLink>
            <NavLink
              onClick={handleClose}
              to="/contact-us"
              className={({ isActive }) =>
                `${isActive ? "activeLink" : "normalLink"}`
              }
            >
              Contact Us
            </NavLink>
            <NavLink
              onClick={handleClose}
              to="/privacy-policy"
              className={({ isActive }) =>
                `${isActive ? "activeLink" : "normalLink"}`
              }
            >
              Privacy Policy
            </NavLink>
            <IoWarning
              onClick={() => {
                handleOpenDisclamerModal();
                handleClose();
              }}
              style={{
                color: "#ffbc03",
                fontSize: "25px",
                cursor: "pointer",
              }}
            />
            <Button onClick={handleOpenLogin} className={styles.loginBtn}>
              Sign Up / Log In
            </Button>
            <Button 
            onClick={() => {
              naviagte("/contact-us")
              handleClose();
            }}
           className={styles.loginBtn}>
                Demo
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NavBar;
